@import "./index.module.scss";

.pace {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.25);
  opacity: 1;
  z-index: 2000;
  -webkit-transition: all 2s linear 0s;
  -moz-transition: all 2s linear 0s;
  transition: all 2s linear 0s;
}

.pace .paceProgress {
  position: absolute;
  display: block;
  left: 50%;
  bottom: 0;
  z-index: 1910;
  margin-left: -30px;
  width: 60px;
  height: 75px;
  background: rgba(20, 20, 20, 0.1);
  box-shadow: 0 0 20px 35px rgba(20, 20, 20, 0.1);
  border-radius: 30px/40px;
  -webkit-transform: scaleY(0.3) !important;
  -moz-transform: scaleY(0.3) !important;
  -ms-transform: scaleY(0.3) !important;
  -o-transform: scaleY(0.3) !important;
  transform: scaleY(0.3) !important;
  -webkit-animation: pace-compress 0.5s infinite alternate;
  -moz-animation: pace-compress 0.5s infinite alternate;
  -o-animation: pace-compress 0.5s infinite alternate;
  -ms-animation: pace-compress 0.5s infinite alternate;
  animation: pace-compress 0.5s infinite alternate;
}

@keyframes pace-compress {
  0% {
    bottom: 0;
    margin-left: -30px;
    width: 60px;
    height: 75px;
    background: rgba(20, 20, 20, 0.1);
    box-shadow: 0 0 20px 35px rgba(20, 20, 20, 0.1);
    border-radius: 30px/40px;
    animation-timing-function: ease-in;
  }

  100% {
    bottom: 30px;
    margin-left: -10px;
    width: 20px;
    height: 5px;
    background: rgba(20, 20, 20, 0.3);
    box-shadow: 0 0 20px 35px rgba(20, 20, 20, 0.3);
    border-radius: 20px;
    animation-timing-function: ease-out;
  }
}

.pace .paceActivity {
  width: 140px;
  height: 140px;
  border-radius: 70px;
  background: $theme-color;
  position: absolute;
  top: 0;
  z-index: 1911;
  -webkit-animation: pace-bounce 1s infinite;
  -moz-animation: pace-bounce 1s infinite;
  -o-animation: pace-bounce 1s infinite;
  -ms-animation: pace-bounce 1s infinite;
  animation: pace-bounce 1s infinite;
}

@keyframes pace-bounce {
  0%,
  100%,
  95% {
    top: 0;
    animation-timing-function: ease-in;
  }

  50% {
    top: 140px;
    height: 140px;
    animation-timing-function: ease-out;
  }

  55% {
    top: 160px;
    height: 120px;
    border-radius: 70px/60px;
    animation-timing-function: ease-in;
  }

  65% {
    top: 120px;
    height: 140px;
    border-radius: 70px;
    animation-timing-function: ease-out;
  }
}

.paceProgressInner {
  color: $theme-color;
}
